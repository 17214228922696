import { useMemo, useState } from 'react'
import type { LanguageCode } from '@sevenrooms/core/api'
import { useLocales, Locale } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon, Icons } from '@sevenrooms/core/ui-kit/icons'
import { Box, Flex, HStack, Modal, ModalBody, ModalHeader, Root, Spinner, Window } from '@sevenrooms/core/ui-kit/layout'
import { gxTheme } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { buttonOptions } from '@sevenrooms/mgr-settings-subscription-widget/SubscriptionWidgetSettings/ButtonPicker'
import { subscriptionWidgetMessages } from '@sevenrooms/widget-subscription/subscriptionWidgetMessages'

interface SubscriptionWidgetEmbedProps {
  buttonConfig: (typeof buttonOptions)[number]
  buttonText: string
  color: string
  embedSrc: string
  lang: LanguageCode
}

function HeaderContent() {
  const { formatMessage } = useLocales()
  return (
    <Text color="primaryFont" fontSize="xxl" fontWeight={600} textDisplay="flex">
      <Flex alignItems="center">{formatMessage(subscriptionWidgetMessages.emailSubscriptionHeader)}</Flex>
    </Text>
  )
}

export function SubscriptionWidgetEmbed({ color, buttonConfig, buttonText, embedSrc, lang }: SubscriptionWidgetEmbedProps) {
  const urlLanguageLocale = Locale.getLocale(lang)
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const { size, iconSize, padding, variant, textColor } = buttonConfig
  const themeOverride = useMemo(
    () => ({
      colors: {
        ...gxTheme.colors,
        brandColor: color,
        secondaryFont: color,
        primaryIcons: color,
      },
    }),
    [color]
  )

  return (
    <Root theme="gx" themeOverride={themeOverride} locale={urlLanguageLocale}>
      <Window active={isActive} setIsActive={setIsActive}>
        <Modal ariaLabel="Subscription Modal" width="88vw">
          <ModalHeader
            pl="xl"
            pr="l"
            pb="m"
            position="relative"
            onClose={() => {
              setIsActive(false)
              setIsLoading(true)
            }}
          >
            <HeaderContent />
          </ModalHeader>
          <ModalBody pl="none" pr="none" height="88vh" overflow="hidden">
            {isLoading && (
              <Flex height="100%" alignItems="center" justifyContent="center">
                <Spinner />
              </Flex>
            )}
            <iframe
              src={embedSrc}
              title="Subscription Form"
              height="100%"
              width="100%"
              frameBorder="0"
              onLoad={() => setIsLoading(false)}
            />
          </ModalBody>
        </Modal>
      </Window>
      <Button noPadding size={size} variant={variant} onClick={() => setIsActive(true)} data-test="subscription-widget-embed-button">
        <HStack {...padding} spacing={size}>
          <Box>
            <Icon name={Icons['VMSWeb-7r-logo']} color={textColor} size={iconSize} />
          </Box>
          <Box>
            <Text color={textColor} fontSize={size}>
              {buttonText}
            </Text>
          </Box>
        </HStack>
      </Button>
    </Root>
  )
}
